<template>
  <v-container id="test" fluid tag="section">
    <v-row>
      <v-col>
        <v-text-field v-model="name" label="name" clearable />
      </v-col>
      <v-col cols="auto">
        <v-card-actions class="pa-0">
          <v-spacer />
          <v-btn color="success" min-width="100" @click="getHello">GET</v-btn>
          <v-btn color="success" min-width="100" @click="sayHello">POST</v-btn>
          <v-btn color="success" min-width="100" @click="callGraphAPI"
            >Call GraphAPI
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        response message: {{ responseMsg }}
      </v-col>
      <v-col cols="12" class="text-center">
        response header: {{ responseHeaders }}
      </v-col>
      <v-col cols="12" class="text-center">
        response status: {{ responseStatus }}
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" top centered>
      {{ notificationMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    name: "World",
    responseMsg: "",
    responseHeaders: "",
    responseStatus: "",
    snackbar: false,
    notificationMessage: "",
  }),
  methods: {
    async getAccessToken() {
      const accessTokenRequest = { scopes: ["user.read"] };
      const { accessToken } = await this.$msal.acquireToken(accessTokenRequest);
      return accessToken;
    },

    async callGraphAPI() {
      try {
        const accessToken = await this.getAccessToken();
        const { data, headers, statusText } = await axios.get(
          "https://graph.microsoft.com/v1.0/me",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        this.responseMsg = data;
        this.responseHeaders = headers;
        this.responseStatus = statusText;
      } catch (e) {
        console.error(e);
      }
    },

    async getHello() {
      try {
        const token = this.$msal.data.idToken;
        const { data, headers, statusText } = await axios.get("/api/hello", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.responseMsg = data;
        this.responseHeaders = headers;
        this.responseStatus = statusText;
      } catch (e) {
        this.responseMsg = e.response.data;
        this.responseHeaders = e.response.headers;
        this.responseStatus = e.response.statusText;
        console.error(e);
      }
    },

    async sayHello() {
      try {
        const token = this.$msal.data.idToken;
        const { data, statusText } = await axios.post(
          "/api/hello/call",
          {
            name: this.name,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.responseMsg = data.msg;
        this.responseStatus = statusText;
      } catch (e) {
        const { response } = e;
        if (response) {
          this.showNotification(response.data);
        } else {
          this.showNotification(e);
        }
        this.responseMsg = "";
        this.responseStatus = "";
      }
    },

    showNotification(msg) {
      this.notificationMessage = msg;
      this.snackbar = true;
    },
  },
};
</script>
